<template>
  <div>
    <section class="py-0">
      <iframe
        src="https://maps.google.com/maps?q=%CE%9B%CE%B5%CF%89%CF%86%CF%8C%CF%81%CE%BF%CF%82%20%CE%9C%CE%B5%CF%83%CE%BF%CE%B3%CE%B5%CE%AF%CF%89%CE%BD%20410&t=&z=15&ie=UTF8&iwloc=&output=embed"
        width="100%"
        height="600"
        frameborder="0"
        style="border: 0"
        allowfullscreen
      />
    </section>

    <section>
      <v-container>
        <v-row justify="center">
          <v-col
            v-for="([icon, info1, info2], i) in details"
            :key="i"
            cols="12"
            md="3"
          >
            <div class="text-center">
              <v-icon
                size="64"
                class="mb-3"
                v-text="icon"
              />
              <div
                class="mb-2 headline"
                v-text="info1"
              />
              <div v-text="info2" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <form
        ref="form"
        @submit.prevent="sendEmail"
      >
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12"
              md="8"
            >
              <h1
                class="mb-4"
                :class="[
                  $vuetify.breakpoint.xsOnly ? 'display-1' : 'display-1',
                ]"
              >
                Στείλτε το μήνυμά σας<br>
              </h1>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="name"
                outlined
                label="Το όνομά σας*"
                name="user_name"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="email"
                type="email"
                name="user_email"
                outlined
                label="Το Email σας*"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="message"
                name="message"
                outlined
                label="Το μήνυμά σας"
              />
            </v-col>
            <v-col
              text-center
              md="8"
            >
              <v-btn
                type="submit"
                value="Send"
                :block="$vuetify.breakpoint.smAndDown"
                class="px-5 text-capitalize"
                color="success"
                large
              >
                Αποστολή
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </section>
  </div>
</template>

<script>
  import emailjs from 'emailjs-com'
  export default {
    data: () => ({
      details: [
        ['mdi-map-marker', 'Λεωφόρος Μεσογείων 410', 'Αγ. Παρασκευή'],
        ['mdi-clock', 'Δευτέρα - Παρασκευή', '09:30 - 13:30, 17:00 - 20:30'],
        ['mdi-phone', '+30 210 600 0014', '+30 210 601 2458'],
        ['mdi-cellphone', '+30 698 286 7069', '+30 697 715 4272']
      ]
    }),

    methods: {
      sendEmail () {
        emailjs.sendForm('service_2vm3ndd', 'template_98q320k', this.$refs.form, 'user_8TQvqEnbBZmwr9vXrAUi3')
          .then((result) => {
            console.log('SUCCESS!', result.text)
            alert('Message Sent!')
          }, (error) => {
            console.log('FAILED...', error.text)
          })
      }
    }
  }
</script>
